/* Colour Palette https://colorhunt.co/palette/22283131363f76abaeeeeeee */

p{
  font-size: 18px;
}

/* h1{
  color: #31363F;
} */

h3{
  color: #31363F;
}

.blue-text{
  color: #6181ce;
}

/* Button */
.btn {
  font-weight: 450;
  border-width: 1px;
  /* border-radius: 25px; */
}

/* Fade In Animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px); /* Start 30px lower */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at original position */
  }
}

.fade-in-up {
  opacity: 0; /* Element is initially invisible */
  animation: fadeInUp 1s ease-out forwards; /* 1s animation duration */
  /* Customize animation timing as needed */
}

/* Navbar */
.logo-img {
  max-width: 125px;
}

.container-fluid{
  padding: 0;
}

.navbar{
  background-color: black;
}

.navbar-collapse{
  padding: 0px 12px;
  @media (max-width: 991px){
    text-align: center;
    /* background: linear-gradient(0deg, rgba(241,255,255,1) 0%, rgba(231,247,247,1) 2%, rgba(241,255,255,1) 100%); */
    background-color: black;
  }
}

.nav-item{
  font-weight: 600;
}

.navbar-brand{
  padding-left: 12px;
  padding-right: 12px;
}

.gradient-background {
  /* background: #f1ffff; */
}

.title {
  margin-bottom: 0 !important;
  color: black;
}

.nav-link {
  margin-top: 5px;
  margin-right: 10px;
  color: white;
}

.navbar-toggler-icon {
  border: none; /* Remove the border */
  width: 1.5em; /* Adjust the width as needed */
  height: 1.5em; /* Adjust the height as needed */
}

.navbar-toggler {
  border-color: transparent; /* Change the border color to white */
}

.navbar-toggler:focus {
  border-color: transparent; /* Change the border color to white */
}

/* JumbotronAni */
.landing-heading-mask{
  z-index: 2; /* Ensure it stays above the canvas */
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Added to stack items vertically */
  justify-content: center;
  align-items: center;
}

.landing-heading{
  color: white;
}

.typing-animation{
  animation: blink-caret .75s step-end infinite;
  border-right: .30em solid orange;
  padding-right: 5px;
}

.typing-animation h1{
  margin-bottom: 0;
  color: orange;
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

/* Jumbotron */
.jumbotron {
  padding-top: 30px !important; 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: black;
}

.landing {
  background-image: url('./images/blue-wave.webp');
  height: 100%;
}

.landing-heading {
  font-weight: 700;
}

.landing-text {
  max-width: 500px;
  font-weight: 450;
  @media (max-width:600px){
    text-align: left;
  }
}

.landing-section {
  @media (min-width: 1140px){
    margin-top: 50px;
  }
}

.landing-img {
  max-width: 100%;
}

/* Process */
.process-section{
  padding-top: 50px;
  padding-bottom: 5px;
  background-color: #1C1C1C;
  color: white;
}

/* Accordion */
.accordion-heading{
  font-weight: 800;
  color: orange;
}

.accordion-text{
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.accordion{
  margin-bottom: 75px;
  margin-top: 30px;
  background-color: transparent;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.accordion-item{
  border-right: none;
  border-left: none;
  background-color: transparent;
}

.accordion-item:first-of-type{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:last-of-type{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-button:not(.collapsed) {
  color: orange;
  background-color: transparent;
}

.accordion-button {
  color: white;
  background-color: transparent;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button h4{
  font-weight: 700;
}

.accordion-body{
  color: white;
}

/* Footer */
.footer {
  padding: 30px 0;
  background-color: black;
}

.footer a{
  color: white;
  font-size: 14px;
  text-decoration: none;
  margin: 0 15px;
  @media (max-width: 480px){
    margin: 15px 15px;
    display: block;
  }
}

.footer a:hover{
  cursor: pointer;
}

.footer-divider{
  color: white;
  margin: 30px 25px;
}

.footer-text {
  margin-bottom: 0;
  font-size: 12px;
  color: darkgray
}

/* Home Content */
.home-content-one{
  padding-top: 75px;
  padding-bottom: 75px;
}

.home-content-one-heading{
  font-weight: 800;
}

.home-content-one-body{
  margin: auto;
  max-width: 650px;
}

.home-content-one-price-btn{
  margin-top: 25px;
}

.content-two{
  background-color: #1C1C1C;
  /* background-image: url('./images/light-blue-wave.webp');
  @media (max-width: 991px){
    background-image: url('./images/light-blue-wave-tall.webp');
  } */
  color: white;
  padding-top: 25px;
  padding-bottom: 25px;
}

.content-two-heading{
  font-weight: 800;
  color: white;
}

.content-two-body{
  padding-top: 25px;
  padding-bottom: 25px;
}

.content-two-card{
  padding: 10px 25px;
  border-radius: 25px;
}

.content-two-card-heading{
  font-weight: 625;
  color: orange;
}

.content-two-card-body{
  max-width: 350px;
  margin: auto;
}

.content-two-card-icon{
  max-width: 125px;
  margin-bottom: 25px;
  margin-top: 15px;
}

.content-three-bg{
  background-color: #F5F5F5;
}

.content-three{
  padding-top: 5px;
  padding-bottom: 50px;
}

.content-three-image{
  max-width: 100%;
  border-radius: 20%;
}

.home-content-three-heading{
  margin-top: 25px;
  font-weight: 800;
}

.home-content-three-row{
  margin-top: 50px;
}

.home-content-three-col-heading{
  @media (min-width: 767px){
    margin-left: 25px;
  }
  margin-left: 15px;
  margin-top: 25px;
  font-weight: 800;
  color: #31363F;
}

.content-three-body{
  margin-left: 15px;
  @media (min-width: 767px){
    margin-left: 25px;
  }
  max-width: 460px;
}

.home-content-three-body{
  margin: 0 auto;
  max-width: 650px;
}

.home-content-three-col-body{
  margin-left: 15px;
  @media (min-width: 767px){
    margin-left: 25px;
  }
}

.content-three-col-checkbox-img{
  max-width: 24px;
}

/* Contact Us Form */
.contact-us{
  padding: 50px 0;
  color: white;
  /* background-color: #3a4542; */
  /* background-image: url('./images/light-blue-wave.png');
  background-size: cover; */
  font-size: 18px;
}

.contact-us-heading{
  font-weight: 800;
  color: white;
}

.contact-us-form{
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.contact-us-bg{
  height: 35vh;
  background-size: cover;
  background-position: center;
  background-repeat: none;
  background-image: url('./images/conversation-4.webp');
  @media (min-width: 2065px){
    height: 50vh;
  }
}

.about-bg{
  height: 25vh;
  background-size: cover;
  background-position: bottom;
  background-repeat: none;
  background-image: url('./images/blue-towers.webp');
  @media (min-width: 2065px){
    height: 40vh;
  }
}

.about-heading-mask{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-heading{
  font-weight: 800;
}

.about-content{
  margin: 50px 50px;
  @media (max-width: 991px){
    margin: 50px 25px;
  }
}

.about-content h3{
  font-weight: 700;
}

.about-peanut-img{
  max-width: 250px;
  border-radius: 100%;
  margin-bottom: 25px;
}

.dots-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* Create a grid with 8 columns */
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  position: relative;
}

.dots-background-container {
  position: relative;
  width: 100%;
  height: 40vh;
  @media (max-width: 500px){
    height: 95vh;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #000;
}

/* Canvas covers the entire div */
.dots-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Background layer */
}